.shop_intro {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    // border: 1px solid #b5954c;
    // border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Apple Chancery", sans-serif;
}

.shop_intro_title {
    text-align: left;
    margin-left: 10px;
}

.shop_intro_content {
    margin-left: 10px;
    text-align: left;
    font-size: larger;
}

a {
    color: rgb(4, 109, 255);
    text-decoration: none;
}

.btn {
    background: #b5954c;
}

.calendar_title {
    margin-left: 8px;
    margin-right: 8px;
}
