.container {
    max-width: 80%;
}

.dialog_container {
    width: auto;
    margin: auto;
}

.image {
    width: 80%;
    height: auto;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
