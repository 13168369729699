.instagram {
    background: #f09433;
    background: -moz-linear-gradient(
        45deg,
        #f09433 5%,
        #e6683c 25%,
        #dc2743 55%,
        #cc2366 75%,
        #bc1888 100%
    );
    background: -webkit-linear-gradient(
        45deg,
        #f09433 5%,
        #e6683c 25%,
        #dc2743 55%,
        #cc2366 75%,
        #bc1888 100%
    );
    background: linear-gradient(
        45deg,
        #f09433 5%,
        #e6683c 25%,
        #dc2743 55%,
        #cc2366 75%,
        #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.instagram:hover {
    background: #f5f5f5;
}

.facebook {
    background: #0076de96;
}

.message {
    background: #34da4f;
}
