.formation_intro {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    // border: 1px solid #b5954c;
    // border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 6rem;
    font-family: "Apple Chancery", sans-serif;
}

.formation_intro_title {
    text-align: left;
    margin-left: 10px;
}

.formation_intro_content {
    margin-left: 10px;
    text-align: left;
    font-size: larger;
}

.mail {
    text-decoration: wavy;
    color: #b78d3e;
}

.div_schedule {
    font-family: "Apple Chancery", sans-serif;
    font-size: x-large;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    // border: 2px solid #b5954c;
    // border-radius: 10px;
}

.schedule_list {
    text-decoration: none;
    text-align: left;
}

.schedule_title {
    text-decoration: underline;
}
