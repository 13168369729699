$global-font-family: "Apple Chancery", sans-serif;

.home_intro {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    // border: 1px solid #b5954c;
    // border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: $global-font-family;
}

.home_intro_title {
    text-align: left;
    margin-left: 10px;
}

.home_intro_content {
    margin-left: 10px;
    text-align: left;
    font-size: larger;
}

.container_logo {
    margin-top: 20px;
    border: 1px solid #b5954c;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.changing_image_container {
    margin-top: 10px;
    .changing_img {
        height: 200px;
        img {
            object-fit: contain;
        }
    }
}

.logo {
    object-fit: cover;
    width: 300px;
    height: auto;
    margin-left: 30px;
    margin-top: 20px;
}

.list {
    margin-top: 0;
}

.div_list {
    margin-top: 1rem;
}
