.title {
    height: 30px;
    width: 30%;
}
.title_calendar {
    font-family: "Apple Chancery", sans-serif;
}
.App {
    text-align: center;
}

input {
    margin-left: 5px;
}

.month {
    border-radius: 4px;
    border-bottom: 2px solid #acacac;
    font-family: "Apple Chancery", sans-serif;
}

.intro {
    font-size: 20px;
}

.year {
    font-size: larger;
    font-weight: bold;
    font-size: 40px;
    border-radius: 10px;
    border: 1px solid #e2e2e1;
    width: fit-content;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Apple Chancery", sans-serif;
}

.formation {
    border-radius: 10px;
    background-color: #d1ac97;
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 4px;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    font-family: "Apple Chancery", sans-serif;
    font-size: larger;
}

.year_grid {
    margin-bottom: 10px;
}

.open_icon {
    vertical-align: middle;
}

.link {
    font-family: "Apple Chancery", sans-serif;
    text-decoration: none;
    color: #2f47ff;
}

.dialog_title {
    text-align: left;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: "Apple Chancery", sans-serif;
}

.dialog_content {
    font-weight: 500;
    font-size: 16px;
    font-family: "Apple Chancery", sans-serif;
}

.content {
    font-family: "Apple Chancery", sans-serif;
    font-size: large;
}

.subtitle {
    font-weight: bolder;
    font-size: large;
}

.sized_content {
    font-size: large;
}

.intro_div{
    text-align: left;
    width:50%;
    margin:auto;
     border-radius: 10px;
    border: 1px solid #cccccc;
    padding: 25px;
}

.facebook_link{
    color:#4968ad;
}

.phone_link{
    color:#000000;
}