.therapy_intro {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 6rem;
    font-family: "Apple Chancery", sans-serif;
}

.therapy_intro_title {
    text-align: left;
    margin-left: 10px;
    font-family: "Apple Chancery", sans-serif;
}

.therapy_intro_content {
    margin-left: 10px;
    text-align: left;
    font-size: larger;
}

.imageTherapy {
    border-radius: 15px;
    max-width: 80%;
    height: auto;
    margin: auto;
}

.citation {
    text-align: center;
    margin: auto;
    font-size: larger;
}

.citation_text {
    color: #b5954c;
}
